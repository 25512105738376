.SystemBalanceSignalGraphicWrapper{ 
  display: grid;
  grid-template-columns: 81% 19%;
  height: 50vh;
}

.darkChart .ag-chart-tooltip-content{
  background-color: #171A1C;
  border-color: aliceblue;
}

.sbscrosshair-dark *{
  color: #171A1C;
}

.ag-crosshair-label.sbscrosshair-dark .ag-crosshair-label-content{
  background-color: #D6E0E5 !important;
}