.darkGreen {
  /* background-color: #5CB85C; */
  background-color: rgba(92, 184, 92, 0.7);
}

.lightGreen {
  /* background-color: #AED581; */
  background-color: rgba(174, 213, 129, 0.5);
}
.orange {
  /* background-color: #FFB74D; */
  background-color: rgba(229, 115, 115, 0.2);
}

.gridReact {
  height: 120vh;
  margin: 1rem 0;
  padding: 0 2rem;
}

.gridReactTitle {
  width: 100%;
  justify-content: center;
  text-align: center;
  font-size: 1.5rem;
  margin: 1rem 0;
}

.textLeft {
  text-align: right;
}
