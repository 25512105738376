.ImbalanceSignalWrapper{ 
  display: grid;
  grid-template-columns: 80.5% 19%;
  margin-left: -2.7rem;
  height: 50vh;
}

.darkChart .ag-chart-tooltip-content{
  background-color: #171A1C;
  border-color: aliceblue;
}